<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button class="ml-3"/>
    <div class="ml-3 d-flex justify-content-between">
      <h1>{{ 'Add Program User' }}</h1>
    </div>
    <program-user-form ref="form" @store="storeProgramUser" form-type="store" :loader="loader"/>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton"
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin'
import ProgramUserForm from "@/views/Components/SuperAdmin/ProgramUser/Form"

export default {
  name: "StoreProgramUser",
  components: {
    ProgramUserForm,
    BackButton
  },
  data() {
    return {
      loader: false,
    }
  },
  mixins: [generateErrorMessageMixin],
  methods: {
    /**
     * Add Program User
     * @param formData
     */
    storeProgramUser(formData) {
      let vm = this
      this.loader = true
      vm.$store.dispatch('ProgramUserModule/_storeProgramUser', {formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Program User Created Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Program User',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  },
}
</script>

<style scoped>

</style>
